import React from 'react'

const Worker = ({elem}) => {
  return (
    <div className="item">
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="team-box">
                <figure>
                    <img src={elem.image} alt=""/>

                </figure>
                <div className="caption bg-color">
                    <h4><a href="#">{elem.name}</a></h4>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }}></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Worker