import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';

const Footer = ({item}) => {
    const [logo, setLogo] = useState();
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [text, setText] = useState();
    const {localizationsItems} = useLangContext();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials"))
            setLogo(item.find(it => it.type === "white-logo")?.value)
            setEmail(item.find(it => it.type === "email"))
            setAddress(item.find(it => it.type === "address"))
            setPhone(item.find(it => it.type === "phone"))
            setText(item.find(it => it.type === "footer_text"))
        }
    }, [item])

  return (
        <footer>
            <div className="top-footer padTB100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 margin-small">
                            <div className="widget">
                                <a href="index.html" className="logo">
                                <img src={logo} alt="LOGO"/>
                                </a>
                                {text?.value.map((em, index) => 
                                    <p key={index}>{em}</p>
                                )}
                                {socials?.value.find(el => el.value) &&
                                    <ul className="social-icon-footer circle box">
                                        {socials?.value.map((social, index) =>
                                            social.value &&
                                            <li key={index}>
                                                <NavLink to={social.value} target="_blank">
                                                    <i className={
                                                                social.type === "facebook" ? "fa fa-facebook-official"
                                                                :
                                                                social.type === "twitter" ? "fa fa-twitter"
                                                                :
                                                                social.type === "instagram" ? "fa fa-instagram"
                                                                :
                                                                social.type === "youtube" ? "fa fa-youtube"
                                                                :
                                                                social.type === "telegram" ? "fa fa-telegram"
                                                                :
                                                                social.type === "whatsapp" ? "fa fa-whatsapp"
                                                                :
                                                                "fa fa-facebook-official"
                                                    }></i>
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                }
                                <p>&copy; ТОО Canvas, 2012</p>
                            </div>
                        </div>
                        {items?.length &&
                            <div className="col-md-2 col-sm-6 col-xs-12 margin-small">
                                <div className="widget information">
                                    <h4>{localizationsItems?.info}</h4>
                                    <ul>
                                        {items?.map((it, index) =>
                                            <li key={index}><a href={it.value}>{it.title}</a></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className="col-md-4 col-sm-6 col-xs-12 margin-small">
                            <div className="widget">
                                <h4>{localizationsItems?.footer_title}</h4>
                                <ul className="contact-footer">
                                    {email &&
                                        <li>
                                            {email.value.map((em, index) => 
                                                <a href={`mailto:${em}`} key={index}><i className="fa fa-envelope-o"></i>{em}</a>
                                            )}
                                        </li>
                                    }
                                    {phone &&
                                        <li>
                                            {phone.value.map((phone, index) => 
                                                <a href={`tel:${phone}`} key={index}><i className="fa fa-phone"></i>{phone}</a>
                                            )}
                                        </li>
                                    }
                                    {address &&
                                        <li>
                                            {address.value.map((em, index) => 
                                                <div key={index}><i className="fa fa-map-marker"></i>{em}</div>
                                            )}
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Footer