import React from 'react'

const HelpSection = ({item}) => {
  return (
        <section className="bg-color padTB100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="template-heading">
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12 pull-right">
                        <figure className="why_choose_us_img">
                            <img src={item.steps_image} alt=""/>
                        </figure>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 pull-left">
                        <div className="why_choose_us">
                            <ul>
                                {item.elements?.map((elem, index) =>
                                    <li key={index}>
                                        <div className="web_design">
                                            <h4><span>0{index+1}</span>{elem.title}</h4>
                                            <p>{elem.subtitle}</p>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default HelpSection