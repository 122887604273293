import React from 'react'
import PackageCard from '../simple/PackageCard'

const Pricing = ({item}) => {
  return (
        <section className="padTB100" id="pricing">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="template-heading">
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                    {item.elements?.map((card, index) => 
                        <PackageCard card={card} key={index}/>
                    )}
                </div>
            </div>
        </section>
  )
}

export default Pricing