import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import Footer from '../components/complex/Footer';
import Header from '../components/complex/Header';
import useFetchItems from '../hooks/useFetchItems';
import AppServices from '../services/general/appServices';
import { MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';

const AppRouter = () => {
  const { 
    items,
  } = useFetchItems(AppServices.settings);


  useEffect(() => {
    if(items){
      let color = items.find(it => it.type === "color-primary")?.value;
      let menuColor = items.find(it => it.type === "menu_back_color")?.value;
      let headerTopColor = items.find(it => it.type === "header_top_color")?.value;
      let menuFontColor = items.find(it => it.type === "menu-font-color")?.value;
      let headerFontColor = items.find(it => it.type === "header-font-color")?.value;
      let footerColor = items.find(it => it.type === "footer_color")?.value;
      let mainFont = items.find(it => it.type === "font-primary")?.value;

      color && document.documentElement.style.setProperty('--mainColor', color)
      menuColor && document.documentElement.style.setProperty('--menuColor', menuColor)
      headerTopColor && document.documentElement.style.setProperty('--headerColor', headerTopColor)
      menuFontColor && document.documentElement.style.setProperty('--menuFontColor', menuFontColor)
      headerFontColor && document.documentElement.style.setProperty('--headerFontColor', headerFontColor)
      footerColor && document.documentElement.style.setProperty('--footerColor', footerColor)

      if(mainFont){
        let elements = mainFont.elements
        for(let i =0; i<elements.length; i++){
          const font_name = new FontFace(mainFont.font_name, `url(${elements[i].link})`, {
            style: elements[i].type,
            weight: elements[i].value
          });
          document.fonts.add(font_name);
        }
        document.documentElement.style.setProperty('--fontFamily', `${mainFont.font_name}, sans-serif`)
      }
    }
  }, [items])

  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={
            <>
              <Header item={items}/>
              <Component></Component>
              <Footer item={items}/>
            </>
          } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={MAIN_PAGE_ROUTE}/>}
        />

    </Routes>
  )
}

export default AppRouter