import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';

const ContactUs = ({item}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const {localizationsItems} = useLangContext();

    const {handleSubmit, register, reset, setValue, control, formState: {errors}} = useForm();

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    
    const CustomSubmit = async (data) => {
        setLoading(true)
        let newData = {
            fields: {}
        }

        for(var key in data){
            let temp = item.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        const res = await AppServices.request(data);

        if (res.statusCode === 200) {
            setVisible(true);
            reset();
            setTimeout(() => {
                setVisible(false)
            }, 3000)
        }
        setLoading(false)
    }

  return (
        <section id="contact_us" className="bg-color padTB100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="template-heading">
                            <h2>{item.title}</h2>
                        </div>
                    </div>
                    <div className={loading ? "template-form isLoading" : 'template-form'}>
                        <form onSubmit={handleSubmit(CustomSubmit)}>
                            {item.elements?.map((elem, index) =>
                                <div className="col-md-4 col-sm-4 col-xs-12" key={index}>
                                    <input {...register(elem.name)} type="text" name={elem.name} placeholder={elem.placeholder}/>
                                </div>
                            )}
                            <div className="col-xs-12">
                                <button type="submit" className="itg-button active">{localizationsItems?.button_send}</button>
                            </div>
                        </form>
                        <div className={visible ? 'success-request success-request-visible' : 'success-request'}>
                            <span className='request-span'>
                                <i className='fa fa-check-circle'></i>
                            </span>
                            <h3>{localizationsItems?.success}</h3>
                            <p>{localizationsItems?.success_request}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ContactUs