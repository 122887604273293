import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import img1 from "../../img/all/client-1.jpg"
import ReviewCard from '../simple/ReviewCard';

const Testimonial = ({item}) => {
    const options = {
        loop: true,
        margin: 0,
        nav: false,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        dots: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }


  return (
        <section id="testimonial" className="padTB100">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-xs-12">
                        <div className="template-heading white">
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                    {item.elements &&
                        <OwlCarousel className="testimonial-slider owl-carousel owl-theme" {...options}>
                            {item.elements.map((card, index) =>
                                <ReviewCard card={card} key={index}/>
                            )}
                        </OwlCarousel>
                    }
                </div>
            </div>
        </section>
  )
}

export default Testimonial