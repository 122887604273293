import React from 'react'

const BlogCard = ({card}) => {
  return (
    <div className="col-md-4 col-sm-6 col-xs-12 margin-small">
        <div className="blog">
            <figure>
                <img src={card.preview_image} alt=""/>
                <figcaption>
                    <span><a href="#"><i className="fa fa-link" aria-hidden="true"></i></a></span>
                </figcaption>
            </figure>
            <div className="blog-caption">
                <div className="blog-meta">
                    <a className="date">{card.date}</a>
                </div>
                <div className="blog-detail bg-color">
                    <h4><a href="#">{card.title}</a></h4>
                    <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BlogCard