import React from 'react'
import ServiceCard from '../simple/ServiceCard'

const Services = ({item}) => {
  return (
    <section id="services" className="padTB100">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="template-heading">
                        <h3>{item.title}</h3>
                    </div>
                </div>
                {item.elements?.map((card, index) =>
                    <ServiceCard card={card} key={index}/>
                )}
            </div>
        </div>
    </section>
  )
}

export default Services