import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import { Collapse } from 'react-bootstrap';
import { MAIN_PAGE_ROUTE } from '../../navigation/Constants';

const Header = ({item}) => {
    const [logo, setLogo] = useState();
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [searchEnabled, setSearchEnabled] = useState();
    const [visible, setVisible] = useState();
    const {localizationsItems} = useLangContext();

    const [open, setOpen] = useState(false)

    const [sticky, setStiky] = useState();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo").value)
            setSocials(item.find(it => it.type === "socials"))
            setEmail(item.find(item => item.type === "email")?.value)
            setPhone(item.find(it => it.type === "phone").value)
            setLogo(item?.find(it => it.type === "logo").value)
            setSearchEnabled(item?.find(it => it.type === "search_enabled").value)
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 20){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
        <header id="header">
            <div className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12 left">
                            <ul>
                                <li>
                                    {phone?.map((el, index) =>
                                        <a href={`tel:${el}`} key={index}><i className="fa fa-phone"></i> {el}</a>
                                    )}
                                </li>
                                <li className="envolop">
                                    {email?.map((em, index) =>
                                        <a href={`mailto:${em}`} key={index}><i className="fa fa-envelope-open" aria-hidden="true"></i> {em}</a>
                                    )}
                                </li>
                            </ul>
                        </div>
                        {socials?.value.find(el => el.value) &&
                            <div className="col-md-6 col-sm-6 col-xs-12 right">
                                <ul>
                                    <li>{localizationsItems?.share}</li>
                                    {socials?.value.map((social, index) =>
                                        social.value &&
                                            <li key={index}>
                                                <NavLink to={social.value} target="_blank">
                                                    <i className={
                                                                social.type === "facebook" ? "fa fa-facebook-official"
                                                                :
                                                                social.type === "twitter" ? "fa fa-twitter"
                                                                :
                                                                social.type === "instagram" ? "fa fa-instagram"
                                                                :
                                                                social.type === "youtube" ? "fa fa-youtube"
                                                                :
                                                                social.type === "telegram" ? "fa fa-telegram"
                                                                :
                                                                social.type === "whatsapp" ? "fa fa-whatsapp"
                                                                :
                                                                "fa fa-facebook-official"
                                                    }></i>
                                                </NavLink>
                                            </li>
                                    )}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div id="main-menu" className={sticky ? "wa-main-menu sticky-nav sticky-header" : "wa-main-menu sticky-nav"}>
                <div className="wathemes-menu relative">
                    <div className="navbar navbar-default mar0" role="navigation">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 head-box justify-content-between">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggle" 
                                            onClick={() => setOpen(!open)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                        >
                                        <span className="sr-only">Переключить навигацию</span> 
                                        <span className="icon-bar"></span> 
                                        <span className="icon-bar"></span> 
                                        <span className="icon-bar"></span>
                                        </button> 
                                        <NavLink className="navbar-brand" to={MAIN_PAGE_ROUTE}>
                                            <img className="site_logo" alt="Site Logo"  src={logo} />
                                        </NavLink>
                                    </div>
                                    <Collapse in={open} className="navbar-collapse">
                                        <div id="example-collapse-text">
                                            {items &&
                                                <ul className="nav navbar-nav">
                                                    {items.map((elem, index) =>
                                                        <li key={index}><a href={elem.value}>{elem.title}</a></li>
                                                    )}
                                                </ul>
                                            }
                                            {searchEnabled && 
                                                <div className="serach-footer hidden-xs" onClick={() => setVisible(true)}><i className="fa fa-search" aria-hidden="true"></i></div>
                                            }
                                        </div>
                                    </Collapse>
                                    <div id="popup1" className={visible ? "overlay popup-visible" : "overlay"}>
                                            <div className="search-section">
                                                <div className="col-md-10 col-md-offset-1 col-sm-8 col-sm-offset-2">
                                                    <div className="popup">
                                                        <div className="search-header">
                                                            <h4>раздел поиска</h4>
                                                            <div className="close" onClick={() => setVisible(false)}>&times;</div>
                                                        </div>
                                                        <div className="content-search">
                                                            <form className="form-inline">
                                                                <div className="col-sm-8 col-xs-8 form-group top_search" style={{paddingRight:"0px"}}>
                                                                    <div className="row">
                                                                        <label className="sr-only">Поищи здесь...</label>
                                                                        <span className="serach-bottom"><i className="fa fa-search"></i></span>
                                                                        <input type="text" className="form-control search-wrap" id="search" placeholder="Search here..."/>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-4 col-xs-4 form-group top_search" style={{paddingLeft:"0px"}}>
                                                                        <button className="btn btn-default search-btn">ПОИСК</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
  )
}

export default Header