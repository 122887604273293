import React, { useEffect } from 'react'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems'
import { useLangContext } from '../../i18n/ProvideLang';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from '../../components/complex/Banner';
import Aboutus from '../../components/complex/Aboutus';
import OurTeam from '../../components/complex/OurTeam';
import Services from '../../components/complex/Services';
import OurFacts from '../../components/complex/OurFacts';
import OurBlog from '../../components/complex/OurBlog';
import HelpSection from '../../components/complex/HelpSection';
import Gallery from '../../components/complex/Gallery';
import Testimonial from '../../components/complex/Testimonial';
import Pricing from '../../components/complex/Pricing';
import FullSection from '../../components/complex/FullSection';
import ContactUs from '../../components/complex/ContactUs';
import Preloader from '../../components/simple/Preloader'

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

    useEffect(() => {
        if(lang){
          navigate(`/${lang}/${slug}`)
        }
    }, [lang])

  return (
    <>
      {items ?
        items.elements?.map((elem, index) => 
          <div key={index}>
              {elem.type === "opening-3" ?
                <Banner item={elem}/>
                :
                elem.type === "about" ?
                <Aboutus item={elem}/>
                :
                elem.type === "workers" ?
                <OurTeam item={elem}/>
                :
                elem.type === "advantages" ?
                  elem.elements.find(it => it.image) ?
                  <Services item={elem}/>
                  :
                  <OurFacts item={elem}/>
                :
                elem.type === "articles" ?
                <OurBlog item={elem}/>
                :
                elem.type === "steps" ?
                <HelpSection item={elem}/>
                :
                elem.type === "projects" ?
                <Gallery item={elem}/>
                :
                elem.type === "reviews" ?
                <Testimonial item={elem}/>
                :
                elem.type === "packages" ?
                <Pricing item={elem}/>
                :
                elem.type === "advertising-banner-2" ?
                <FullSection item={elem}/>
                :
                elem.type === "form" ?
                <ContactUs item={elem}/>
                :
                <></>
              }
          </div>
        )
        :
        <Preloader/>
      }
    </>
  )
}

export default SamplePage