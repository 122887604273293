import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ModalApplication from './ModalApplication';

const Banner = ({item}) => {
    const [image, setImage] = useState();
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(item){
            setImage(item.elements.find(it => it.type === "banner_image")?.image)
        }
    }, [item])

    const handleShow = () => {
        setShow(true)
    }

  return (
    <div className="banner-section">
        <img src={image} alt="" />
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <div className="slide-caption">
                        <div className="slide-content">
                            <h2>{item.subtitle}</h2>
                            <h1 className="s-font" dangerouslySetInnerHTML={{ __html: item.title }}></h1>
                            <span className="itg-button">{localizationsItems?.read_more}</span>
                            <span className="itg-button active" onClick={handleShow}>{localizationsItems?.more}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalApplication show={show} setShow={setShow}/>
    </div>
  )
}

export default Banner