import React from 'react'
import img1 from "../../img/all/team-1.jpg"
import OwlCarousel from 'react-owl-carousel';
import Worker from '../simple/Worker';

const OurTeam = ({item}) => {
    const options = {
        loop: true,
        margin: 0,
        nav: true,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    }
  return (
    <section id="our_team" className="padT100 padB70">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="template-heading">
                        <h3>{item.title}</h3>
                    </div>
                </div>
                {item.elements &&
                    <OwlCarousel className="team_slider owl-carousel owl-theme" {...options}>
                        {item.elements.map((elem, index) => 
                            <Worker elem={elem} key={index}/>
                        )}
                    </OwlCarousel>
                }
            </div>
        </div>
    </section>
  )
}

export default OurTeam