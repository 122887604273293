import React from 'react'

const ReviewCard = ({card}) => {
  return (
    <div className="item">
        <div className="col-xs-12 col-sm-12 col-xs-12">
            <div className="clients">
                <div className="client-img-designation">
                    <figure className="client-img">
                        <img src={card.image} alt=""/>
                    </figure>
                </div>
                <div className="client-content">
                    <h4>{card.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
                    <span>{card.subtitle}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReviewCard