import React, { Suspense } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProvideLang from "./i18n/ProvideLang";
import "./App.scss"
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './navigation/AppRouter';

const App = () => {
  return (
    <Suspense fallback={null}>
      <ProvideLang>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      </ProvideLang>
    </Suspense>
  )
}

export default App