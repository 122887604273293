import React, { useState } from 'react'

const ProjectCard = ({card, ...props}) => {

  return (
    <div className="col-md-3 col-sm-3 col-xs-12 margin-small" {...props}>
        <div className="row">
            <div className="gallery_image">
                <figure>
                    <img src={card.image} alt=""/>
                    <figcaption>
                        <span>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </span>
                    </figcaption>
                </figure>
            </div>
        </div>
    </div>
  )
}

export default ProjectCard