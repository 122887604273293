import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ModalApplication from './ModalApplication';

const FullSection = ({item}) => {
    const [banner, setBanner] = useState();
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(item){
            setBanner(item.elements[0])
        }
    }, [item])

    const handleShow = () => {
        setShow(true)
    }

  return (
    <section className="full_section theme-bg">
        <img src={banner?.image} alt="" />
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 ">
                    <div className="full_section_content">
                        <p>{banner?.subtitle_top}</p>
                        <h2>{banner?.title}</h2>
                        <span className="itg-button" onClick={handleShow}>{localizationsItems?.more}</span>
                    </div>
                </div>
            </div>
        </div>
        <ModalApplication show={show} setShow={setShow}/>
    </section>
  )
}

export default FullSection