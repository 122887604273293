import React, { useEffect } from 'react'
import Banner from '../../components/complex/Banner'
import Services from '../../components/complex/Services'
import Aboutus from '../../components/complex/Aboutus'
import FullSection from '../../components/complex/FullSection'
import OurTeam from '../../components/complex/OurTeam'
import HelpSection from '../../components/complex/HelpSection'
import Testimonial from '../../components/complex/Testimonial'
import Gallery from '../../components/complex/Gallery'
import OurFacts from '../../components/complex/OurFacts'
import Pricing from '../../components/complex/Pricing'
import OurBlog from '../../components/complex/OurBlog'
import ContactUs from '../../components/complex/ContactUs'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems'
import Preloader from '../../components/simple/Preloader'
import { useLangContext } from '../../i18n/ProvideLang'
import { useNavigate } from 'react-router-dom'

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
  
    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')


    useEffect(() => {
        if(lang){
          navigate(`/${lang}`)
        }
    }, [lang])

  return (
    <>
      {items ?
        items.elements?.map((elem, index) => 
          <div key={index}>
              {elem.type === "opening-3" ?
                <Banner item={elem}/>
                :
                elem.type === "about" ?
                <Aboutus item={elem}/>
                :
                elem.type === "workers" ?
                <OurTeam item={elem}/>
                :
                elem.type === "advantages" ?
                  elem.elements.find(it => it.image) ?
                  <Services item={elem}/>
                  :
                  <OurFacts item={elem}/>
                :
                elem.type === "articles" ?
                <OurBlog item={elem}/>
                :
                elem.type === "steps" ?
                <HelpSection item={elem}/>
                :
                elem.type === "projects" ?
                <Gallery item={elem}/>
                :
                elem.type === "reviews" ?
                <Testimonial item={elem}/>
                :
                elem.type === "packages" ?
                <Pricing item={elem}/>
                :
                elem.type === "advertising-banner-2" ?
                <FullSection item={elem}/>
                :
                elem.type === "form" ?
                <ContactUs item={elem}/>
                :
                <></>
              }
          </div>
        )
        :
        <Preloader/>
      }
    </>
  )
}

export default MainPage