import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices';

const ModalApplication = ({show, setShow}) => {
    const handleClose = () => setShow(false)
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);

    const {handleSubmit, register, reset, setValue, control, formState: {errors}} = useForm();

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        let query = new URLSearchParams(window.location.search);

        let newData = {...data}

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        setLoading(true);
        const res = await AppServices.request(data);
    
        if (res.statusCode === 200) {
            setVisible(true)
            reset()
            setTimeout(() => {
                setVisible(false)
                setShow(false);
            }, 3000);
        }
        setLoading(false);
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
            <span className="btn-close" onClick={handleClose}>
                x
            </span>
        </Modal.Header>
        <Modal.Body className={loading ? 'modal-new isLoading' : 'modal-new'}>
            <div className="row">
                <div className="col-md-12 justify-content-center mb-3">
                    <h3>Заполните форму</h3>
                </div>
                <div className="col-md-12 justify-content-center mb-3">
                    <p>Для вступления в членство ОЮЛиИП «Национальная ассоциация женщин предпринимателей Казахстана «ASMAR»</p>
                </div>
            </div>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <div className="mb-3">
                    <input {...register('name', {required: true})} type="text" placeholder={localizationsItems?.name}/>
                </div>
                <div className="mb-3">
                    <input {...register('phone', {required: true})} type="tel" placeholder={localizationsItems?.phone}/>
                </div>
                <div className="mb-3">
                    <input {...register('email')} type="email" placeholder={localizationsItems?.your_email}/>
                </div>

                <button className="itg-button active">
                    Отправить
                </button>
            </form>
            <div className={visible ? 'success-request success-request-visible' : 'success-request'}>
                            <span className='request-span'>
                                <i className='fa fa-check-circle'></i>
                            </span>
                            <h3>{localizationsItems?.success}</h3>
                            <p>{localizationsItems?.success_request}</p>
                        </div>
        </Modal.Body>
    </Modal>
  )
}

export default ModalApplication