import React, { useRef } from 'react'
import useOnScreen from '../../hooks/useOnScreen.hook'
import CountUp from 'react-countup';

const OurFacts = ({item}) => {
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

  return (
        <section className="our-facts" ref={ref}>
            <div className="container">
                {item.elements?.map((elem, index) =>
                    <div className="col-md-3 col-sm-3 col-xs-12 margin-small" key={index}>
                        <div className="row">
                            <div className="facts">
                                {elem.counter &&
                                    <div className="count-number" data-count="1500">
                                        <h2><span className="counter">
                                                {isVisible && <CountUp start={0} end={elem.counter} duration={2.75}/>}
                                        </span></h2>
                                    </div>
                                }
                                <h3>{elem.title}</h3>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
  )
}

export default OurFacts