import React from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from "../../services/general/articleServices"
import BlogCard from '../simple/BlogCard';

const OurBlog = ({item}) => {
    const { 
        items,
    } = useFetchItems(ArticleServices.index);

  return (
        <section id="our_blog" className="padTB100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="template-heading">
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                    {items?.items.map((card, index) =>
                        <BlogCard key={index} card={card}/>
                    )}
                </div>
            </div>
        </section>
  )
}

export default OurBlog