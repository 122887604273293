import React, { useState } from 'react'
import ProjectCard from '../simple/ProjectCard'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import { Modal } from 'react-bootstrap'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Gallery = ({item}) => {
    const [activeItem, setActiveItem] = useState();

    const {
        items,
    } = useFetchItems(ProjectServices.index)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    
    const handleShow = (index) => {
        setShow(true);
        setActiveItem(index)
    };

  return (
        <section id="gallery" className="padB100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="template-heading">
                            <h3>{item.title}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    {items?.items.map((card, index) =>
                        <ProjectCard card={card} key={index} onClick={() => handleShow(index)}/>
                    )}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <span className="btn-close" onClick={handleClose}>
                        x
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Carousel showThumbs={false} showStatus={false} selectedItem={activeItem}>
                        {items?.items.map((card, index) =>
                            <div key={index} >
                                <img src={card.image} alt="" />
                            </div>
                        )}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </section>
  )
}

export default Gallery