import React, { useEffect, useState } from 'react'
import img1 from "../../img/all/about-1.jpg"
import img2 from "../../img/all/about-2.jpg"
import img3 from "../../img/all/about-3.jpg"
import OwlCarousel from 'react-owl-carousel';

const Aboutus = ({item}) => {
    const [imgs, setImgs] = useState();
    const [texts, setTexts] = useState();

    useEffect(() => {
        if(item){
            setImgs(item.elements.find(it => it.type == "images").elements)
            setTexts(item.elements.find(it => it.type == "text").elements)
        }
    }, [item])

    const options = {
        loop: true,
        margin: 0,
        nav: true,
        navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }
  return (
    <section id="about_us" className="padTB100">
        <div className="about-us-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12  pull-left">
                        <div className="template-heading">
                            <h3>{item.subtitle}</h3>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12  pull-left">
                        {imgs &&
                            <OwlCarousel className="about-slider style-one owl-theme" {...options}>
                                {imgs.map((image, index) => 
                                    <div className="item" key={index}>
                                        <figure>
                                            <img src={image} alt=""/>
                                        </figure>
                                    </div>
                                )}
                            </OwlCarousel>
                        }
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 pull-right">
                        <div className="about-content">
                            <h3>{item.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                            <ul className="about-list">
                                {texts?.map((text, index) => 
                                    <li key={index}>
                                        <div className="about_box">
                                            <img src={text.image} alt="" />
                                        </div>
                                        <div className="about_box_content">
                                            <h4><a href="#">{text.title}</a></h4>
                                            <p dangerouslySetInnerHTML={{ __html: text.description }}></p>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Aboutus