import React from 'react'

const ServiceCard = ({card}) => {
  return (
    <div className="col-md-4 col-sm-6 col-xs-12 margin-small">
        <div className="service-box">
            <div className="service-content">
                <div className="service-icon">
                    <img src={card.image} alt="" />
                </div>
                <h4>{card.title}</h4>
                <p>{card.subtitle}</p>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard