import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ModalApplication from '../complex/ModalApplication';

const PackageCard = ({card}) => {
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

    const handleShow = () => {
      setShow(true)
  }
  return (
    <div className="col-md-3 col-sm-6 col-xs-12 margin-small">
        <div className="pricing-detail resmar pricemar">
            <div className="pricing-head">
                <h2>{card.price}</h2>
                <span>{card.date}</span>
            </div>
            <p>{card.subtitle}</p>
            <h4>{card.type}</h4>
            <div dangerouslySetInnerHTML={{ __html: card.description }}>

            </div>
            <span onClick={handleShow}>{localizationsItems?.start}</span>
        </div>
        <ModalApplication show={show} setShow={setShow}/>
    </div>
  )
}

export default PackageCard